import { ChevronLeftIcon } from '@heroicons/react/outline';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Layout from '../../components/layout/layout';
import { Headline } from '../../components/layout/typography';

const LaunchpadPage = () => {
  return (
    <Layout
      title="Founders' Launchpad"
      description="Maximize your startup's success with the Founders' Launchpad"
    >
      <section id="solution" className="pt-32 bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <div>
              <nav aria-label="Back">
                <a
                  href="/services"
                  className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <ChevronLeftIcon
                    className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Back
                </a>
              </nav>
            </div>
          </div>
          <div className="py-12 w-full flex flex-col items-center">
            <p className="block text-base text-center font-semibold tracking-wide uppercase">
              Maximize your startup&apos;s success with the
            </p>
            <Headline className="mt-2 block text-5xl text-center font-extrabold tracking-tight text-6xl sm:text-4xl tracking-tight font-extrabold lg:text-5xl xl:text-6xl">
              Founders&apos; <br />
              Launchpad
            </Headline>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <StaticImage
                      className="w-full"
                      src="../../images/launchpad.png"
                      alt="A picture of the workshop Miro"
                      placeholder="blurred"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="mt-6 prose prose-red prose-lg text-gray-500 pb-8">
                <h2>Data Beats Opinions. Period.</h2>
              </div>
              <div className="text-lg text-gray-500 max-w-prose mx-auto lg:max-w-none">
                <p className="mb-4">
                  Noone knows your business quite as you do. But getting the
                  necessary learnings that make or break your startup is hard. I
                  will guide you through this slippery slope to build your
                  startup based on facts, not assumptions.
                </p>
                <p>All sessions can be done remotely or face-to-face.</p>
              </div>
              <div className="mt-5 prose prose-red text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <h3>That&apos;s in it for you</h3>
                <ul role="list">
                  <li>
                    A Kick-Off Workshop (90 min.) where we dive deep into your
                    startup. We will come up with a Lean Canvas, the riskiest
                    parts of your business and actionable experiments to test
                    those parts.
                  </li>
                  <li>
                    Two Iteration Workshops (45 min. each) where we consolidate
                    learnings and define the next set of experiments.
                  </li>
                  <li>This will take place over the course of 3 weeks.</li>
                  <li>
                    Summaries of the workshops, learnings, metrics and
                    conclusions that will catapult your startup towards success.
                  </li>
                </ul>
                <h3>What you need to provide</h3>
                <ul role="list">
                  <li>Your idea, ideally written down on a Lean Canvas</li>
                  <li>
                    A time slot of 45 to 90 minutes on a fixed weekday for 3
                    weeks with all necessary people.
                  </li>
                  <li>
                    Time and budget to conduct the experiments (1 to 2 workdays
                    and 50€ to 100€ per iteration).
                  </li>
                </ul>
                <p className="text-4xl font-extrabold text-gray-900">
                  Book now for 499€
                </p>
              </div>
              <div className="mt-10 flex text-base max-w-prose mx-auto lg:max-w-none">
                <div className="rounded-md shadow">
                  <a
                    href="mailto:hello@timothy.de?subject=Make%20my%20startup%20fly%20with%20the%20Founders'%20Launchpad%2C%20please!&body=Hey%20Timothy%2C%0D%0A%0D%0AI%20am%20interested%20in%20the%20Founders'%20Launchpad.%20Can%20we%20arrange%20a%20call%3F%0D%0A%0D%0ACheers%2C%0D%0A"
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-timothy-purple hover:bg-purple-900"
                  >
                    I&apos;m interested!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LaunchpadPage;

const Info = () => (
  <section id="hero" className="lg:h-screen-4/5 w-full flex flex-wrap">
    <div className="w-full lg:w-1/2 h-full pt-32 px-2 lg:px-8 flex items-end lg:items-center lg:justify-end">
      <div className="w-full lg:w-auto text-center lg:text-left z-10">
        <Headline className="text-6xl sm:text-4xl tracking-tight font-extrabold lg:text-5xl xl:text-6xl">
          Timothy
          <br />
          Krechel
        </Headline>
      </div>
    </div>
  </section>
);
